import { Table } from "antd";
import { useEffect, useState } from "react";
import { getOrders } from "../api/Api";
import { timeSince } from "../utils/time";

interface order {
    createAt: string
    name: string
    phone: string
    items: string
}

export function Orders() {

    const [orders, setOrders] = useState<order[]>([])

    useEffect(() => {
        getOrders().then((ordersApi) => {
            setOrders((ordersApi.data || []).map((order) => {
                console.log(order)
                return {
                    createAt: timeSince(new Date(order.createAt)),
                    name: order.name,
                    phone: order.phone,
                    items: order.items.map((item) => item.articul + (item.title.ru || "") + item.amount).join(", \n")
                }
            }))
        })
    }, [])

    const columns = [
        {
            title: 'Date',
            dataIndex: 'createAt',
            key: 'createAt',
        },
        {
            title: 'Items',
            dataIndex: 'items',
            key: 'items',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
        },
    ];


    return (
        <div>
            Orders
            <Table dataSource={orders} columns={columns} />
        </div>
    )
}