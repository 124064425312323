import { useCallback, useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { MLWord } from "../models/base"
import { GlobalContext } from "../ctx/context"
import { Preview, PreviewItem } from "../models/publish"
import { debounse } from "../utils/time"
import { ItemModal } from "../components/itemModal"

interface SearchStruct {
    categoryName: string
    items: PreviewItem[]
    count: number
}


export function FindPage() {
    const searchParams = new URLSearchParams(window.location.search)
    const { preview, language, itemModal, cartIsOpen } = useContext(GlobalContext)
    const [count, setCount] = useState<number>(12)
    let nowRenderCount = count

    const navigate = useNavigate()

    const [search, setSearch] = useState<string>("")
    const [searchItems, setSearchItems] = useState<SearchStruct[]>([])
    const find = useCallback(debounse((searchW: string, previewNow: Preview | null) => {
        const searchItems: SearchStruct[] = []
        previewNow?.sections?.forEach((section) => {
            section.categories?.forEach((category) => {
                const items: PreviewItem[] = []
                let categoryName = ""
                category.items?.forEach((item) => {
                    if (section.withItems) {
                        categoryName = section.name[language]
                    } else {
                        categoryName = section.name[language] + "/" + category.name[language]
                    }
                    if (searchW[0] === "#") {
                        if (item.tags?.includes(searchW)) {
                            items.push(item)
                        }
                    } else {
                        if (item.title[language].toLowerCase().includes(searchW.toLocaleLowerCase())) {
                            items.push(item)
                        }
                    }
                })
                if (items.length > 0) {
                    searchItems.push({
                        categoryName: categoryName,
                        items: items,
                        count: items.length
                    })
                }
            })
        })
        setSearchItems(searchItems)
    }, 500), [language])



    useEffect(() => {
        if (count !== 12) {
            setCount(12)
        }
        find(search, preview)
    }, [search, preview])

    useEffect(() => {
        if (searchParams.get("tag")) {
            let tag = searchParams.get("tag")
            if (tag) {
                setSearch(tag)
            }
        }
    }, []);

    useEffect(() => {
        document.getElementById("search-input")?.focus()
    }, [])
    return (
        <>
            <div className="column" />
            <div className="content">
                {!itemModal && !cartIsOpen &&

                    <div style={{ opacity: 1 }}>
                        <div
                            className="Search_main__UJ1RR"
                            style={{ backgroundColor: "rgb(243, 244, 245)" }}
                        >
                            <div
                                className="SearchHead_headerBlock__xLQUx"
                                style={{ backgroundColor: "rgb(255, 255, 255)" }}
                            >
                                <div className="SearchHead_searchBar__uXDc4">
                                    <label
                                        className="SearchHead_search__jvRXP"
                                        style={{ backgroundColor: "rgb(243, 244, 245)" }}
                                    >
                                        <div className="SearchHead_searchIcon__OL_yA">
                                            <svg
                                                width={14}
                                                height={14}
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                style={{ color: "rgba(32, 32, 34, 0.8)" }}
                                            >
                                                <path
                                                    d="M6.5 1a5.5 5.5 0 0 1 4.383 8.823l3.896 3.9a.75.75 0 0 1-1.061 1.06l-3.895-3.9A5.5 5.5 0 1 1 6.5 1Zm0 1.5a4 4 0 1 0 0 8 4 4 0 0 0 0-8Z"
                                                    fill="#9398A1"
                                                />
                                            </svg>
                                        </div>
                                        <input
                                            id="search-input"
                                            placeholder={MLWord.search[language]}
                                            className="SearchHead_searchInput__gXcVM"
                                            style={{
                                                color: "rgb(32, 32, 34)",
                                                backgroundColor: "rgb(243, 244, 245)"
                                            }}
                                            value={search}
                                            onChange={(e) => {
                                                setSearch(e.target.value)
                                            }}
                                        />
                                        <div
                                            className="SearchHead_crossIcon__d0QJR"
                                            style={{ color: "rgba(32, 32, 34, 0.8)" }}
                                        >
                                            <svg
                                                width={16}
                                                height={16}
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                color="#9398A1"
                                            >
                                                <g clipPath="url(#search_cross_svg__a)">
                                                    <path
                                                        d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0Zm2.466 4.26L8 6.727 5.536 4.26a.9.9 0 1 0-1.273 1.273l2.465 2.468-2.465 2.469a.9.9 0 0 0 1.273 1.273L8 9.275l2.466 2.468a.9.9 0 1 0 1.273-1.273L9.271 8.001l2.466-2.468a.9.9 0 1 0-1.272-1.273Z"
                                                        fill="currentColor"
                                                    />
                                                </g>
                                                <defs>
                                                    <clipPath id="search_cross_svg__a">
                                                        <path fill="#fff" d="M0 0h16v16H0z" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                    </label>
                                    <div
                                        className="SearchHead_cancelButton__qVPMc"
                                        style={{ color: "rgb(32, 32, 34)" }}
                                        onClick={() => {
                                            navigate("/menu")
                                        }}
                                    >
                                        {MLWord.back[language]}
                                    </div>
                                </div>
                                <div className="SearchHead_menu___S3pm">
                                    {searchItems.map((searchItem) => {
                                        if (nowRenderCount <= 0) {
                                            return null
                                        }
                                        let localcount = nowRenderCount
                                        nowRenderCount = nowRenderCount - searchItem.count
                                        return (
                                            <SearchItems searchItems={searchItem} count={localcount} />
                                        )
                                    })}
                                    {nowRenderCount <= 0 ? (
                                        <div className="ProductBtn_button__inner__cXv35">
                                            <button
                                                className="ProductBtn_btn__o2Ep6"
                                                style={{ backgroundColor: "rgb(32, 32, 34)", color: "rgb(243, 244, 245)" }}
                                                onClick={() => {
                                                    setCount(count + 12)
                                                }}
                                            >
                                                {MLWord.showMore[language]}
                                            </button>
                                        </div>

                                    ) : null}
                                </div>
                            </div>
                            <div className="Search_menu__9a_bm" />
                        </div>
                    </div>
                }
                <ItemModal />
            </div>
            <div className="column" />
        </>
    )
}

interface SearchItemProps {
    searchItems: SearchStruct
    count: number
}

function SearchItems(props: SearchItemProps) {
    const { language, setItemModal } = useContext(GlobalContext)
    return (
        <div
            className="SearchHead_menuCategoryBlock__UCIHS"
            id="container-0"
        >
            <div
                className="SearchHead_mealTimeName__Fd2An"
                style={{
                    color: "rgb(32, 32, 34)",
                    backgroundColor: "rgb(243, 244, 245)"
                }}
            >
                {props.searchItems.categoryName}
            </div>
            {props.searchItems.items.map((item, index) => {
                if (index > props.count) {
                    return null
                }
                return (
                    <a
                        className="ItemSearchRow_blockWrapper__D2i3x"
                        href="#"
                        onClick={(e) => {
                            e.preventDefault()
                            setItemModal(item)
                        }}
                    >
                        <div className="ItemSearchRow_block__q2o76">
                            <div className="ItemSearchRow_previewBlock__qLQKj">
                                {item.images?.length > 1 ? (
                                    <span
                                        className=" lazy-load-image-background blur lazy-load-image-loaded"
                                        style={{
                                            color: "transparent",
                                            display: "inline-block",
                                            height: 40,
                                            width: 40
                                        }}
                                    >
                                        <img
                                            className="ItemSearchRow_preview__SgUqX"
                                            src={item.images[0]}
                                            width={40}
                                            height={40}
                                            alt="food-preview"
                                            style={{
                                                filter: "none",
                                                transition: "filter 0.3s ease-out 0s"
                                            }}
                                        />
                                    </span>
                                ) : null}
                            </div>
                            <div className="ItemSearchRow_info__xTMDn">
                                <div
                                    className="ItemSearchRow_name__hGg_K"
                                    style={{ color: "rgb(32, 32, 34)" }}
                                >
                                    {item.title[language]}
                                </div>
                            </div>
                        </div>
                    </a>
                )
            })}
        </div>
    )
}