
interface ICategoryProps {
    id: string
    name: string
    children: React.ReactNode
}

export function Category(props: ICategoryProps) {
    return (
        <div id={`link-${props.id}`}>
            <div
                className="CategorySlice_mealTimeName__JeJAe"
                style={{ color: "rgb(32, 32, 34)", marginTop: 8 }}
            >
                {props.name}
            </div>
            <div
                className="CategorySlice_mealTimeDesc__Jk3EF"
                style={{ color: "rgb(32, 32, 34)" }}
            />
            {props.children}

        </div>
    )
}