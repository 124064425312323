export interface I18nString {
    [key: string]: string;
}

export const MLWord:{[key:string]:I18nString} = {
    itemNotFoundMessage: {
        ru: "Товар не найден",
        kz: "Товар табылмады"
    },
    showMore: {
        ru: "Показать еще",
        kz: "Көбірек көрсету"
    },
    back: {
        ru: "Назад",
        kz: "Артқа"
    },
    total: {
        ru: "Всего",
        kz: "Барлығы"
    },
    name:{
        ru:"Имя",
        kz:"Аты"
    },
    phone_number: {
        ru:"Номер телефона",
        kz:"Телефон нөмірі"
    },
    add_phone_for_bill: {
        ru:"Добавить номер для выставления счета",
        kz:"Счет үшін нөмірді қосу"
    },
    submit_your_application:{
        ru:"Оставить заявку",
        kz:"Өтініш қалдыру"
    },
    cart: {
        ru: "Корзина",
        kz: "Себет"
    },
    empty: {
        ru: "Корзина пуста",
        kz: "Себет бос"
    },
    order: {
        ru: "Заказать",
        kz: "Тапсыру"
    },
    search: {
        ru: "Поиск",
        kz: "Іздеу"
    },
    not_available: {
        ru: "Нет в наличии",
        kz: "казір жоқ"
    },
    add_to_cart: {
        ru: "Добавить в корзину",
        kz: "Себетке қосу"
    },
    your_order: {
        ru: "Ваш заказ",
        kz: "Сіздің тапсырысыңыз"
    },
}