import { useEffect, useState } from 'react';
import './App.css';
import { MainPage } from './pages/mainPage';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { CategoriesPage } from './pages/base';
import { CategoryPage } from './pages/createCategory';
import { ItemPage } from './pages/createItem';
import { SaveSectionPage } from './pages/saveSection';
import { Orders } from './pages/orders';
import { ItemRequests } from './pages/leaveRequests';
import { MenuPage } from './pages/menuPage';
import { Scanner } from './pages/scanner';
import { FindPage } from './pages/find';
import { getPublish } from './api/Api';
import { Preview, PreviewItem } from './models/publish';
import { GlobalContext } from './ctx/context';
import { ICart } from './models/cart';
import { message } from 'antd';

const routes = createBrowserRouter([
  { path: '/', element: <MainPage /> },
  { path: '/menu', element: <MenuPage /> },
  { path: '/scanner', element: <Scanner /> },
  { path: "/search", element: <FindPage /> },
  // { path: '/categories', element: <CategoriesPage /> },
  { path: '/admin', element: <CategoriesPage /> },
  { path: '/admin/categories/create', element: <CategoryPage /> },
  { path: '/admin/items/create', element: <ItemPage /> },
  { path: '/admin/categories/:id', element: <CategoryPage /> },
  { path: '/admin/items/:id', element: <ItemPage /> },
  { path: '/admin/section/create', element: <SaveSectionPage /> },
  { path: '/admin/section/:id', element: <SaveSectionPage /> },
  { path: '/admin/orders', element: <Orders /> },
  { path: '/admin/requests', element: <ItemRequests /> }
]);


function App() {

  const [messageApi, contextHolder] = message.useMessage();

  const [lang, setLang] = useState<string>("ru");

  const [PreviewP, setPreviewP] = useState<Preview | null>(null);

  const [item, setItem] = useState<PreviewItem | null>(null);

  const [cart, setCart] = useState<ICart>({});
  const [cartIsOpen, setCartIsOpen] = useState<boolean>(false);

  useEffect(() => {
    getPublish().then((val) => {
      setPreviewP(val.data ? val.data : null);
    });
  }, []);


  return (
    <>
      {contextHolder}
      <div className="App">
        <GlobalContext.Provider value={{
          language: lang,
          setLang: setLang,
          preview: PreviewP,
          itemModal: item,
          setItemModal: setItem,
          cart: cart,
          setCart: setCart,
          cartIsOpen: cartIsOpen,
          setCartIsOpen: setCartIsOpen,
          messageApi: messageApi,
        }}>
          <RouterProvider router={routes} />
        </GlobalContext.Provider>
      </div>
    </>
  );
}

export default App;