import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BarcodeScanner } from "../components/scanner";
import { ItemModal } from "../components/itemModal";
import { GlobalContext } from "../ctx/context";
import { PreviewItem } from "../models/publish";
import { MLWord } from "../models/base";

export function Scanner() {
    const navigate = useNavigate();
    const {setItemModal, itemModal, preview, messageApi, language} = useContext(GlobalContext)

    const [items, setItems] = useState<PreviewItem[]>([]);
    const [code, setCode] = useState("");
    const [error, setError] = useState<Error | null>(null);
    useEffect(() => {
        const b = document.body;
        b.style.setProperty('--st', -(document.documentElement.scrollTop) + "px");
        b.classList.add('noscroll');
        return () => {
            b.classList.remove('noscroll');
            b.style.setProperty('--st', "0px");
        }
    }, [])

    useEffect(() => {
        let nowItems: PreviewItem[] = [];
        if (preview) {
            for (const section of (preview.sections||[])) {
                for (const category of section.categories||[]) {
                    category.items?.forEach((item) => {
                        nowItems.push(item);
                    });
                }
            }
            setItems(nowItems);
        }
    }   , [preview]);
    useEffect(() => {
        if (code.length > 0) {
            const item = items.find((item) => item.articul === code);
            if (item) {
                setItemModal(item);
            } else {
                setError(new Error("Товар не найден"));
                messageApi.info(MLWord["itemNotFoundMessage"][language]);
            }

        }
    }, [code, preview, setItemModal])
    return (

        // <div id="interactive" className="viewport" style={{ background: "#202022", height: window.innerHeight }} />
        <>
            <div className="column" />
            <div className="content">
                <div style={{ opacity: 1,  display:itemModal?"none":"block"}}>
                    <div
                        className="Home_main__Dm3__"
                        style={{
                            backgroundColor: "rgb(243, 244, 245)", paddingBottom: "calc(86px)",
                            height: window.innerHeight
                        }}
                    >
                        <div className="HomeTitle_title__96_tJ">
                            <Link
                                className="HomeTitle_title__text__Sj52W"
                                style={{ color: "rgb(32, 32, 34)" }} to={"/"}                            
                            >
                                SULU.KZ
                            </Link>
                            <svg
                                width={24}
                                height={25}
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="HomeTitle_title__icon__l_0MK"
                                color="rgb(32, 32, 34)"
                            >
                                <path
                                    d="M12 2.9c-5.302 0-9.6 4.298-9.6 9.6s4.298 9.6 9.6 9.6 9.6-4.298 9.6-9.6-4.298-9.6-9.6-9.6Zm.8 14.4h-1.6v-5.6h1.6v5.6ZM12 9.7a1.2 1.2 0 1 1 0-2.4 1.2 1.2 0 0 1 0 2.4Z"
                                    fill="currentColor"
                                />
                            </svg>
                        </div>
                        <div className="HomeDesc_desc__m1TjL">
                            <div
                                className="HomeDesc_desc__text__D2p7A"
                                style={{ color: "rgba(32, 32, 34, 0.8)" }}
                            >
                                {/* Место, где становятся друзьями! */}
                            </div>
                        </div>

                        <BarcodeScanner
                            onResult={(result) => {
                                setCode(result.getText());
                            }}
                            onError={(err) => {
                                setError(err);
                            }}
                        />

                    </div>
                </div>
                <ItemModal />
            </div>
            <div className="column" />
        </>
    );
};
