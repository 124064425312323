import { useEffect, useState } from "react";
import { getSections, saveCategory } from "../api/Api";
import { I18nInputs } from "../components/i18nInput";
import { useNavigate, useParams } from "react-router-dom";
import { Category } from "../models/category";
import { Button, Card, Select, Space } from "antd";
import FormItem from "antd/es/form/FormItem";
import Item from "antd/es/descriptions/Item";
import { Section } from "../models/section";
import { Option } from "antd/es/mentions";

export function CategoryPage() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [category, setCategory] = useState<Category>({
    id: '',
    sectionId: '',
    name: { ru: '', kz: '' },
  });

  const [sections, setSections] = useState<Section[]>([]);

  useEffect(() => {
    getSections().then(r => setSections(r.data));
  }, [id]);
  return (
    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
      <Card title={id ? "Edit Category" : "Create category"} size="default">

        <I18nInputs label="Name" value={category.name} onChange={name => setCategory({ ...category, name })} />

        <FormItem label={"Category"}>
          <Select id="category" value={category.sectionId || sections[0]?.id} onChange={id => setCategory({ ...category, sectionId: id })}>
            {sections.map(section => (
              <Option key={section.id} value={section.id}>{section.name.ru}</Option>
            ))}
          </Select>
        </FormItem>

        <Button onClick={() => saveCategory(category).then(r => !r.err && navigate("/admin"))}>Save</Button>
        <Button type="default" onClick={() => navigate("/admin")}>Cancel</Button>
      </Card>
    </Space>
  )
}

