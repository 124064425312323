import { Carousel } from "antd";

interface ItemImageProps {
    images: string[] | null
    onclick: () => void
}

export function ItemImage(props: ItemImageProps) {
    return (
        <>
            <Carousel style={{
                color: "transparent",
                display: "inline-block",
                height: 138,
                width: 138
            }} dotPosition={"top"}>
                {props.images?.map((image) => {
                    return (
                        <div key={image}>
                            <a
                                className="ItemRow_block__preview__A6uqH"
                                href="#"
                                onClick={props.onclick}
                            >
                                <span
                                    className=" lazy-load-image-background blur lazy-load-image-loaded"
                                    style={{
                                        color: "transparent",
                                        display: "inline-block",
                                        height: 138,
                                        width: 138
                                    }}
                                >
                                    <img
                                        src={`media/${image}`}
                                        width={138}
                                        height={138}
                                        alt="food-preview"
                                        className="ItemRow_preview__UqtRe"
                                        style={{
                                            filter: "none",
                                            transition: "filter 0.3s ease-out 0s"
                                        }}
                                    />
                                </span>
                            </a>
                        </div>
                    )
                })}
            </Carousel>
        </>
    )
}

interface ItemModalImageProps {
    images: string[] | null
    video?: string
}

export function ItemModalImage(props: ItemModalImageProps) {
    let url = props.video?.split("v=")
    console.log(url);
    
    return (
        <>
            <Carousel style={{
                height: 225,
                width: "100%"
            }} dotPosition={"top"}>
                {props.images?.map((image) => {
                    return (
                        <div key={image}>
                            <div
                                className="ProductImage_previewImgWrap__SYU18"
                                style={{ height: 225 }}
                            >
                                <div
                                    className="ProductImage_previewImgLoader__sERAH"
                                    style={{
                                        visibility: "hidden",
                                        transition: "visibility 0ms ease-out 500ms"
                                    }}
                                >
                                    <span
                                        role="img"
                                        aria-label="loading"
                                        className="anticon anticon-loading anticon-spin"
                                        style={{ fontSize: 60, color: "rgb(255, 204, 0)" }}
                                    >
                                        <svg
                                            viewBox="0 0 1024 1024"
                                            focusable="false"
                                            data-icon="loading"
                                            width="1em"
                                            height="1em"
                                            fill="currentColor"
                                            aria-hidden="true"
                                        >
                                            <path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 00-94.3-139.9 437.71 437.71 0 00-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z" />
                                        </svg>
                                    </span>
                                </div>
                                <div
                                    className="swiper swiper-initialized swiper-horizontal swiper-android swiper-backface-hidden"
                                    style={{ margin: 0, width: "100%", height: "100%" }}
                                >
                                    <div
                                        className="swiper-wrapper"
                                        style={{ transform: "translate3d(0px, 0px, 0px)" }}
                                    >
                                        <div
                                            className="swiper-slide swiper-slide-active"
                                            style={{ width: 400 }}
                                        >
                                            <img
                                                id="img-preview"
                                                className="ProductImage_previewImg__uIRPU"
                                                src={`media/${image}`}
                                                alt="food-preview"
                                                style={{ opacity: 1, transition: "opacity 500ms ease-in 0ms" }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
                {props.video &&
                    <div>
                        <iframe id="player" itemType="text/html" height="225" width="100%"
                            src={`http://www.youtube.com/embed/${props.video.split("v=").at(-1)}?enablejsapi=1`}></iframe>
                    </div>
                }
            </Carousel>
        </>
    )
}