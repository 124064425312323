import { useContext } from "react";
import { GlobalContext } from "../ctx/context";
import { Category } from "../models/publish";

interface CategoryHeaderProps {
    list: Category[];
}

export function CategoryHeader(props: CategoryHeaderProps) {
    const { language } = useContext(GlobalContext)
    return (
        <div
            className="CategoriesList_mealTimeSlider__k5_wP"
            style={{ backgroundColor: "rgb(255, 255, 255)", boxShadow: "none" }}
        >
            {props.list.map((cat) => {
                if ((cat?.items||[]).length === 0) {
                    return null;
                }
                return (
                    <div className="CategoriesList_smallBlock__JVVJe">
                        <div
                            className="CategoryItem_block__5WbXg"
                            style={{ backgroundColor: "rgb(243, 244, 245)" }}
                        >
                            <div
                                className="CategoryItem_text__NiotY"
                                style={{ color: "rgba(32, 32, 34, 0.8)" }}
                                onClick={() => {
                                    document.getElementById("link-" + cat.id)?.scrollIntoView();
                                }}
                            >
                                {cat.name[language]}
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}