import { MaskedInput } from "antd-mask-input";
import React, { useMemo } from "react";

interface PhoneInputProps {
    style?: React.CSSProperties,
    onChange: (s:string)=>void
}

export const PhoneInput = (props: PhoneInputProps) => {

  // always memoize dynamic masks
  const mask = useMemo(
    () => [
      {
        mask: '0 (000) 000 00 00',
        lazy: false,
      },
    ],
    []
  );

  return (
    <MaskedInput
    style={props.style}
      mask={mask}
      maskOptions={{
        dispatch: function (appended, dynamicMasked) {
          return dynamicMasked.compiledMasks[0];
        },
      }}
      onChange={(e)=>{
        props.onChange(e.currentTarget.value)
      }}
    />
  );
};