import { useState, useContext, useEffect } from "react";
import { CartModal } from "../components/cartModal";
import { CategoryHeader } from "../components/categoryHeader";
import { Category } from "../components/categpry";
import { Item } from "../components/item";
import { ItemModal } from "../components/itemModal";
import { SearchBar } from "../components/searchBar";
import { SectionHeader } from "../components/sectionHeader";
import { GlobalContext } from "../ctx/context";
import { Link } from "react-router-dom";

export function MenuPage() {
    const searchParams = new URLSearchParams(window.location.search);

    const { setLang, preview, language, cartIsOpen, itemModal, setItemModal, cart, setCart, setCartIsOpen } = useContext(GlobalContext);

    const [nowSectionIndex, setNowSectionIndex] = useState<number>(0);

    useEffect(() => {
        if (searchParams.get("section")) {
            let id = searchParams.get("section")
            let index = preview?.sections?.findIndex((section) => section.id === id);
            if (index !== undefined && index !== -1) {
                setNowSectionIndex(index);
            }
        }
    }, []);
    return (
        <>
            <div className="column"></div>
            <div className="content">
                {!itemModal && !cartIsOpen &&
                    <div style={{ opacity: 1 }}>
                        <div
                            className="List_main__D9atA"
                            style={{
                                backgroundColor: "rgb(255, 255, 255)",
                                paddingBottom: "calc(86px)"
                            }}
                        >
                            <div className="ListHeader_header__ZCk5r" style={{ position: "relative" }}>
                                <div className="ListHeader_lang__mis4_">

                                    <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M13 2.04932C13 2.04932 16 5.99994 16 11.9999C16 17.9999 13 21.9506 13 21.9506" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M11 21.9506C11 21.9506 8 17.9999 8 11.9999C8 5.99994 11 2.04932 11 2.04932" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M2.62964 15.5H21.3704" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M2.62964 8.5H21.3704" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                                    <div className=" css-1v16zl5-container" style={{ width: "auto" }}>
                                        <select className="css-1wy0on6" onChange={(e) => setLang(e.target.value)} style={{ border: "none", background: "#f3f4f5" }}>
                                            <option value="ru">RU</option>
                                            <option value="kz">KZ</option>
                                        </select>
                                    </div>
                                </div>
                            </div>


                            <Link className="ListName_restaurantName__pdjkT" to={"/"}>
                                <div
                                    className="ListName_restaurantNameText__5cbvc"
                                    style={{ color: "rgb(32, 32, 34)" }}
                                >
                                    SULU.KZ
                                </div>
                                <div className="ListName_restaurantNameIcon__TUThH">
                                    <svg
                                        width={24}
                                        height={25}
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        color="rgb(32, 32, 34)"
                                    >
                                        <path
                                            d="M12 2.9c-5.302 0-9.6 4.298-9.6 9.6s4.298 9.6 9.6 9.6 9.6-4.298 9.6-9.6-4.298-9.6-9.6-9.6Zm.8 14.4h-1.6v-5.6h1.6v5.6ZM12 9.7a1.2 1.2 0 1 1 0-2.4 1.2 1.2 0 0 1 0 2.4Z"
                                            fill="currentColor"
                                        />
                                    </svg>
                                </div>
                            </Link>


                            <SearchBar />

                            <SectionHeader sections={preview?.sections || []}
                                sectionIndex={nowSectionIndex} setSectionIndex={setNowSectionIndex} />
                            {preview?.sections?.[nowSectionIndex].categories?.[0].id !== preview?.sections?.[nowSectionIndex].id && (
                                <CategoryHeader list={preview?.sections?.[nowSectionIndex].categories || []} />
                            )}


                            <div className="ProductList_menu__sb84q">
                                {preview?.sections?.[nowSectionIndex].categories?.map((category) => {
                                    if (!category.items || category.items.length === 0) return (<></>)
                                    return (
                                        <Category key={category.id} id={category.id} name={category.name[language]}>
                                            {category.items?.map((item) => {
                                                return (
                                                    <div
                                                        className="ItemRow_blockWrapper__sYxRB"
                                                        style={{ borderColor: "rgb(231, 231, 231)" }}
                                                    >
                                                        <Item key={item.id} setModalItem={setItemModal} item={item} cart={cart} setCart={setCart} />
                                                    </div>
                                                )
                                            })}
                                        </Category>
                                    )
                                })}
                            </div>
                            <a
                                className="ListCartfloatBtn_cart__wEEeZ"
                                href="#"
                            >
                                {Object.keys(cart).length > 0 &&
                                    <div
                                        className="ListCartfloatBtn_cart__inner__yMrSc ListCartfloatBtn_cart__enter__done__obObG"
                                        style={{ backgroundColor: "rgb(32, 32, 34)" }} onClick={() => setCartIsOpen(true)}
                                    >
                                        <div className="ListCartfloatBtn_cart__icon__TaHke">
                                            <svg
                                                width={30}
                                                height={30}
                                                viewBox="0 0 30 30"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M3 16H27C27.552 16 28 15.552 28 15V13C28 12.448 27.552 12 27 12H3C2.448 12 2 12.448 2 13V15C2 15.552 2.448 16 3 16Z"
                                                    fill="rgb(243, 244, 245)"
                                                />
                                                <path
                                                    d="M3 14L4.721 24.329C4.882 25.293 5.717 26 6.694 26H23.305C24.283 26 25.117 25.293 25.278 24.329L27 14H3ZM16 22C16 22.552 15.552 23 15 23C14.448 23 14 22.552 14 22V17C14 16.448 14.448 16 15 16C15.552 16 16 16.448 16 17V22ZM21 22C21 22.552 20.552 23 20 23C19.448 23 19 22.552 19 22V17C19 16.448 19.448 16 20 16C20.552 16 21 16.448 21 17V22ZM11 22C11 22.552 10.552 23 10 23C9.448 23 9 22.552 9 22V17C9 16.448 9.448 16 10 16C10.552 16 11 16.448 11 17V22Z"
                                                    fill="rgb(243, 244, 245)"
                                                />
                                                <path
                                                    d="M17.3477 4.99164L24 12H21.1668L15.7826 6.3278C15.3781 5.90039 14.6229 5.90039 14.2174 6.3278L8.83324 12H6L12.6523 4.99164C13.2495 4.3615 14.1047 4 15 4C15.8953 4 16.7505 4.3625 17.3477 4.99164Z"
                                                    fill="rgb(243, 244, 245)"
                                                />
                                            </svg>
                                        </div>
                                        <div
                                            className="ListCartfloatBtn_cart__price__XWMXZ"
                                            style={{ color: "rgb(243, 244, 245)" }}
                                        >
                                            {Object.values(cart).reduce((acc, cur) => acc + (cur.count * cur.item.price), 0)} ₸
                                        </div>
                                    </div>
                                }

                            </a>
                        </div>
                    </div>
                }
                <ItemModal />
                <CartModal />
            </div>
            <div className="column"></div>
        </>
    )
}