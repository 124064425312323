import { useContext, useEffect, useState } from "react";
import { UploadExcel, deleteCategory, deleteItem, downloadExcel, getCategories, getItems, getSections, publish } from "../api/Api";
import { Category } from "../models/category";
import { Link } from "react-router-dom";
import { Item } from "../models/item";
import { Button, Card, QRCode, notification } from "antd";
import { Section } from "../models/section";
import { NotificationPlacement } from "antd/es/notification/interface";
import { GlobalContext } from "../ctx/context";

const styles = {
    card: {
        display: "flex",
        justifyContent: "space-between",
        border: "1px solid #ccc",
        marginBottom: "10px",
        padding: "10px",
        borderRadius: "5px",
        backgroundColor: "#f5f5f5",
        alignItems: "center"
    },
}

export function CategoriesPage() {
    const {messageApi} = useContext(GlobalContext);

    const [Sections, setSections] = useState<Section[]>([]);
    useEffect(() => {
        getSections().then(sections => {
            console.log(sections);
            setSections(sections.data ? sections.data : []);
        });
    }, []);


    const [Categories, setCategories] = useState<Category[]>([]);
    useEffect(() => {
        getCategories().then(categories => {
            console.log(categories);
            setCategories(categories.data ? categories.data : []);
        });
    }, []);

    const [Items, setItems] = useState<Item[]>([]);
    useEffect(() => {
        getItems().then(items => {
            console.log(items);
            setItems(items.data ? items.data : []);
        });
    }, []);
    return (
        <Card title="Admin panel" type="inner" style={{ width: '100%' }}>
            <Link to="/admin/section/create" style={{ marginLeft: 20 }}>Create Section</Link>
            {Sections.length !== 0 &&
                <Link to="/admin/categories/create" style={{ marginLeft: 20 }}>Create category</Link>}
            {Categories.length !== 0 &&
                <Link to="/admin/items/create" style={{ marginLeft: 20 }}>Create item</Link>
            }
            <Link to="/admin/orders" style={{ marginLeft: 20 }}>Orders</Link>
            <Link to="/admin/requests" style={{ marginLeft: 20 }}>Requests</Link>
            <Button style={{ marginLeft: 20 }} onClick={() => {
                publish().then((val) => {
                    val.err === null|| val.err === "EOF" ? messageApi.info("Published") : messageApi.error(val.err);
                });
            }}>Publish</Button>
            {/* <DownloadQrCodeButton link={`${window.location.origin}`} /> */}
            <UploadExcelButton />
            <DownloadExcelButton/>
            <ul style={{ listStyle: "outside" }}>
                {Sections.map(section => (
                    <li key={section.id}>
                        <div style={styles.card}>
                            <Link to={`/admin/section/${section.id}`}>{section.name.ru}</Link>
                            <div>
                            </div>
                        </div>
                        {section.withItems ?
                            <ul style={{ listStyle: "outside", marginLeft: "80px" }}>
                                {Items.filter(item => item.categoryId === section.id).map(item => (
                                    <AdminItem item={item} />
                                ))}
                            </ul>
                            :
                            <ul style={{ listStyle: "outside", marginLeft: "40px" }}>
                                {Categories.filter((c) => c.sectionId === section.id).map(category => (
                                    <li key={category.id}>
                                        <div style={styles.card}>
                                            <Link to={`/admin/categories/${category.id}`}>{category.name.ru}</Link>
                                            <div>
                                                <Button onClick={() => {
                                                    deleteCategory(category.id).then((val) => {
                                                        if (val.err === null) {
                                                            alert("Deleted");
                                                            window.location.reload();
                                                        }
                                                    });
                                                }}>Delete</Button>
                                            </div>
                                        </div>
                                        <ul style={{ listStyle: "outside", marginLeft: "80px" }}>
                                            {Items.filter(item => item.categoryId === category.id).map(item => (
                                                <AdminItem item={item} />
                                            ))}
                                        </ul>
                                    </li>
                                ))}
                            </ul>
                        }
                    </li>
                ))}
            </ul>
        </Card>
    )
}


function AdminItem({ item }: { item: Item }) {
    return (
        <li key={item.id}>
            <div style={styles.card}>

                <Link to={`/admin/items/${item.id}`}>{item.title.ru}</Link>
                <Button onClick={() => {
                    deleteItem(item.id).then((val) => {
                        if (val.err === null) {
                            alert("Deleted");
                            window.location.reload();
                        }
                    }
                    );
                }
                }>Delete</Button>
            </div>
        </li>)
}

// function DownloadQrCodeButton(props: { link: string, name?: string }) {
//     const downloadQRCode = () => {
//         const canvas = document.getElementById(props.link)?.querySelector<HTMLCanvasElement>('canvas');
//         if (canvas) {
//             const url = canvas.toDataURL();
//             const a = document.createElement('a');
//             a.download = 'QRCode.png';
//             if (props.name) {
//                 a.download = props.name + '.png';
//             }
//             a.href = url;
//             document.body.appendChild(a);
//             a.click();
//             document.body.removeChild(a);
//         }
//     };

//     return (
//         <>
//             <div id={props.link} style={{ display: "none" }}>
//                 <QRCode value={props.link} size={300} />
//             </div>
//             <Button type="primary" onClick={downloadQRCode}>
//                 Download
//             </Button>
//         </>
//     );
// }

function UploadExcelButton() {
    const [api ] = notification.useNotification();

    const openNotification = (mesaage:string) => {
      api.info({
        message: mesaage,
        placement:'topLeft',
      });
    };
    return (
        <Button type="primary" onClick={() => {
            const input = document.createElement('input');
            input.type = 'file';
            input.accept = '.xlsx';
            input.onchange = (event) => {
                const target = event.target as HTMLInputElement;
                const file: File = (target.files as FileList)[0];
                let res = UploadExcel(file);
                res.then((val) => {
                    if (val.err !== null) {
                        openNotification("Uploaded");
                    } else {
                        window.location.reload();
                    }
                });
            }
            input.click();
        }}>
            Upload Excel
        </Button>
    )
}

function DownloadExcelButton() {
    return (
        <Button type="primary" onClick={() => {
            downloadExcel();    
        }}>
            Download Excel
        </Button>
    )
}