import { useContext } from "react"
import { GlobalContext } from "../ctx/context"
import { MLWord } from "../models/base"
import { ICart } from "../models/cart"
import { PreviewItem } from "../models/publish"
import { ItemImage } from "./itemImage"

interface ItemProps {
    item: PreviewItem
    setModalItem: (item: PreviewItem) => void
    cart: ICart
    setCart: (cart: ICart) => void
}

export function Item(props: ItemProps) {
    const { language } = useContext(GlobalContext)

    function plus() {
        props.setCart({
            ...props.cart,
            [props.item.id]: {
                item: props.item,
                count: (props.cart[props.item.id]?.count || 0) + 1
            }
        })
    }

    function minus() {
        if (props.cart[props.item.id]?.count === 1) {
            const newCart = { ...props.cart }
            delete newCart[props.item.id]
            props.setCart(newCart)
            return
        }
        props.setCart({
            ...props.cart,
            [props.item.id]: {
                item: props.item,
                count: (props.cart[props.item.id]?.count || 0) - 1
            }
        })
    }

    return (
        <div
            className={props.item.isOn ? "ItemRow_info__BRCQz" : "ItemRow_block__8yP0M"}
            style={{
                color: "rgb(32, 32, 34)",
                opacity: props.item.isOn ? "none" : "0.6"
            }}
        >
            {props.item.images &&
                <ItemImage images={props.item.images} onclick={() => props.setModalItem(props.item)} />
            }
            <div className="ItemRow_info__BRCQz" style={{ minHeight: 138 }}>
                <div className="Credentials_infoUpper__WFQPP">
                    <a
                        className="Credentials_name__YDEfy"
                        href="#"
                        onClick={() => props.setModalItem(props.item)}
                    >
                        <div style={{ color: "rgb(32, 32, 34)" }}>
                            {props.item.title[language]}
                        </div>
                    </a>
                    <a
                        className="Credentials_description__slQ8D"
                        href="#"
                        onClick={() => props.setModalItem(props.item)}
                    >
                        <div style={{ color: "rgba(32, 32, 34, 0.8)" }}>
                            {props.item.description[language]}
                        </div>
                    </a>
                </div>
                <div className="ItemRow_infoBottom___Wcl7" style={{ display: "flex", justifyContent: "space-between" }}>

                    <div style={{ color: "rgb(147, 152, 161)" }}>
                        {props.item.isOn ? `${props.item.price} ₸` : MLWord.not_available[language]}
                    </div>

                    {props.item.isOn &&
                        <div className="Counter_counter__sS__f" style={{ display: "flex", justifyContent: "space-between" }}>
                            {props.cart[props.item.id]?.count > 0 &&
                                <>
                                    <div className="Counter_counterCircle__left__Taguq" onClick={minus}>
                                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" id="checkmark">
                                            <rect width="24" height="24" rx="12" fill="rgb(243, 244, 245)"></rect>
                                            <rect x="5" y="10" width="14" height="4" fill="rgb(32, 32, 34)"></rect>
                                            <path d="M12 2.3999C6.69842 2.3999 2.40002 6.6983 2.40002 11.9999C2.40002 17.3015 6.69842 21.5999 12 21.5999C17.3016 21.5999 21.6 17.3015 21.6 11.9999C21.6 6.6983 17.3016 2.3999 12 2.3999ZM16.8 12.7999H7.20002C6.75842 12.7999 6.40002 12.4423 6.40002 11.9999C6.40002 11.5575 6.75842 11.1999 7.20002 11.1999H16.8C17.2416 11.1999 17.6 11.5575 17.6 11.9999C17.6 12.4423 17.2416 12.7999 16.8 12.7999Z" fill="rgb(243, 244, 245)"></path>
                                        </svg>
                                    </div>
                                    <div className="Counter_count__FScQe">{props.cart[props.item.id]?.count || 0}</div>
                                </>}
                            <div className="Counter_counterCircle__right__0FEJw" onClick={plus}><svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="24" height="24" rx="12" fill="rgb(243, 244, 245)"></rect>
                                <rect x="6" y="6" width="13" height="12" fill="rgb(32, 32, 34)"></rect>
                                <path d="M12 2.3999C6.69842 2.3999 2.40002 6.6983 2.40002 11.9999C2.40002 17.3015 6.69842 21.5999 12 21.5999C17.3016 21.5999 21.6 17.3015 21.6 11.9999C21.6 6.6983 17.3016 2.3999 12 2.3999ZM16.8 12.7999H12.8V16.7999C12.8 17.2423 12.4416 17.5999 12 17.5999C11.5584 17.5999 11.2 17.2423 11.2 16.7999V12.7999H7.20002C6.75842 12.7999 6.40002 12.4423 6.40002 11.9999C6.40002 11.5575 6.75842 11.1999 7.20002 11.1999H11.2V7.1999C11.2 6.7575 11.5584 6.3999 12 6.3999C12.4416 6.3999 12.8 6.7575 12.8 7.1999V11.1999H16.8C17.2416 11.1999 17.6 11.5575 17.6 11.9999C17.6 12.4423 17.2416 12.7999 16.8 12.7999Z" fill="rgb(243, 244, 245)"></path>
                            </svg></div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}