import { useEffect, useState } from "react";
import { getCategories, getItem, saveItem, uploadImage } from "../api/Api"
import { Item } from "../models/item";
import { I18nInputs, I18nTextArea } from "../components/i18nInput";
import { Category } from "../models/category";
import { Form, useNavigate, useParams } from "react-router-dom";
import { Button, Card, Checkbox, Input, InputNumber, Select, Space, Upload } from "antd";
import FormItem from "antd/es/form/FormItem";
import { Option } from "antd/es/mentions";
import { TagsInput } from "../components/tagsInput";

export function ItemPage() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [Item, setItem] = useState<Item>({
        articul: '',
        id: '',
        categoryId: '',
        createdAt: '',
        title: { ru: '', kz: '' },
        description: { ru: '', kz: '' },
        images: null,
        price: 0,
        youtubeLink: '',
        tags: null,
        isOn: false
    });
    const [Categories, setCategories] = useState<Category[]>([]);
    useEffect(() => {
        getCategories().then(categories => {
            console.log(categories);
            setCategories(categories.data ? categories.data : []);
        });
    }, []);
    useEffect(() => {
        if (!id) return
        getItem(id).then(item => {
            if (!item.data) return
            setItem(item.data);
        });

    }, [id]);
    return (
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            <Card title="Create item" size="default">
                <Form name="basic">
                    <I18nInputs label="Title" value={Item.title} onChange={title => setItem({ ...Item, title })} />
                    <I18nTextArea label="Description" value={Item.description} onChange={description => setItem({ ...Item, description })} />
                    <FormItem label={"Category"}>
                        <Select id="category" value={Item.categoryId || Categories[0]?.id} onChange={id => setItem({ ...Item, categoryId: id })}>
                            {Categories.map(category => (
                                <Option key={category.id} value={category.id}>{category.name.ru}</Option>
                            ))}
                        </Select>
                    </FormItem>
                    <FormItem label={"Image"}>
                        {/* <ImgCrop rotationSlider></ImgCrop> */}
                        <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            fileList={Item.images?.map(v => {
                                return {
                                    uid: v,
                                    name: v,
                                    status: 'done',
                                    url: '/media/' + v,
                                }
                            })}

                            onChange={(e) => {
                                const file = e.file.originFileObj;
                                if (file) {
                                    uploadImage(file).then(r => {
                                        if (r.filename) {
                                            setItem({ ...Item, images: [...(Item.images?.filter((v) => v !== "error") || []), r.filename] });
                                        }
                                    }
                                    );
                                } else {
                                    setItem({ ...Item, images: e.fileList.map(v => v.name) });
                                }
                            }}
                        >
                            <div>
                                <div className="ant-upload-text">Upload</div>
                            </div>
                        </Upload>
                    </FormItem>
                    <FormItem label={"Price"}>
                        <InputNumber id="price" value={Item.price} onChange={price => setItem({ ...Item, price: price || 0 })} />
                    </FormItem>
                    <FormItem label={"tags"}>
                        <TagsInput tags={Item.tags || []} setTags={tags => setItem({ ...Item, tags })} />
                    </FormItem>
                    <FormItem label={"Youtube link"}>
                        <Input value={Item.youtubeLink} onChange={(v) => setItem({ ...Item, youtubeLink: v.target.value })} />
                    </FormItem>
                    <FormItem label={"Is on"}>
                        <Checkbox id="isOn" checked={Item.isOn} onChange={e => setItem({ ...Item, isOn: e.target.checked })} />
                    </FormItem>
                    <Space wrap={true}>
                        <Button type="primary" onClick={() => saveItem(Item).then(r => !r.err && navigate("/admin"))}>Save</Button>
                        <Button type="default" onClick={() => navigate("/admin")}>Cancel</Button>
                    </Space>
                </Form>
            </Card>
        </Space>
    )
}