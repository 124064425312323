import { Link } from "react-router-dom";

export function MainPage() {
    return (
        <>
            <div className="column" />
            <div className="content">
                <div style={{ opacity: 1 }}>
                    <div
                        className="Home_main__Dm3__"
                        style={{
                            backgroundColor: "rgb(243, 244, 245)",
                            paddingBottom: "calc(86px)"
                        }}
                    >
                        <div className="HomePreview_preview__HONQR">
                            <div className="HomePreview_preview__img__DQMBg">
                                <span
                                    className=" lazy-load-image-background blur lazy-load-image-loaded"
                                    style={{ color: "transparent", display: "inline-block" }}
                                >
                                    <img
                                        src="https://kamigroup.fra1.cdn.digitaloceanspaces.com/kami/prod/menuOrgAttrsBackgrounds/10debe2be94145e0a187ce4e655aec5a.jpeg"
                                        style={{ width: "100%", height: "100%" }}
                                        className="HomePreview_preview__img__DQMBg"
                                        alt="bg-preview"
                                    />
                                </span>
                            </div>
                            <div className="HomePreview_logo__Ycoxb">
                                <span
                                    className=" lazy-load-image-background blur lazy-load-image-loaded"
                                    style={{
                                        color: "transparent",
                                        display: "inline-block",
                                        height: 64,
                                        width: 64
                                    }}
                                >
                                    <img
                                        src="/media/sulu.svg"
                                        width={64}
                                        height={64}
                                        style={{
                                            filter: "none",
                                            transition: "filter 0.3s ease-out 0s",
                                            display: "block"
                                        }}
                                        className="HomePreview_logo__img__fW561"
                                        alt="logo"
                                    />
                                </span>
                            </div>

                        </div>
                        <div className="HomeTitle_title__96_tJ">
                            <div
                                className="HomeTitle_title__text__Sj52W"
                                style={{ color: "rgb(32, 32, 34)" }}
                            >
                                SULU.KZ
                            </div>
                            <svg
                                width={24}
                                height={25}
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="HomeTitle_title__icon__l_0MK"
                                color="rgb(32, 32, 34)"
                            >
                                <path
                                    d="M12 2.9c-5.302 0-9.6 4.298-9.6 9.6s4.298 9.6 9.6 9.6 9.6-4.298 9.6-9.6-4.298-9.6-9.6-9.6Zm.8 14.4h-1.6v-5.6h1.6v5.6ZM12 9.7a1.2 1.2 0 1 1 0-2.4 1.2 1.2 0 0 1 0 2.4Z"
                                    fill="currentColor"
                                />
                            </svg>
                        </div>
                        <div className="HomeDesc_desc__m1TjL">
                            <div
                                className="HomeDesc_desc__text__D2p7A"
                                style={{ color: "rgba(32, 32, 34, 0.8)" }}
                            >
                                {/* Место, где становятся друзьями! */}
                            </div>
                        </div>
                        <div
                            className="HomeMainBtn_block__OMr6O"
                            style={{ backgroundColor: "rgb(255, 255, 255)", gap: 14 }}
                        >
                            <Link
                                className="HomeMainBtn_blockItem__fRZLa"
                                style={{ backgroundColor: "rgb(32, 32, 34)" }}
                                to="/menu"
                            >
                                <div className="HomeMainBtn_blockItemLeftTeam__4xvvl">
                                    <svg
                                        width={24}
                                        height={24}
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="HomeMainBtn_blockItemIcon__FwSfm"
                                        color="rgb(243, 244, 245)"
                                    >
                                        <path
                                            d="M12 2.4a2.412 2.412 0 0 0-2.4 2.4c0 .377.098.73.256 1.048C5.588 6.824 2.4 10.636 2.4 15.2v1.6h19.2v-1.6c0-4.564-3.188-8.376-7.456-9.352.158-.319.256-.671.256-1.048 0-1.316-1.084-2.4-2.4-2.4ZM12 4c.451 0 .8.349.8.8 0 .451-.349.8-.8.8a.788.788 0 0 1-.8-.8c0-.451.349-.8.8-.8ZM2.394 18.4a.8.8 0 1 0 0 1.6h19.2a.8.8 0 1 0 0-1.6h-19.2Z"
                                            fill="currentColor"
                                        />
                                    </svg>
                                    <div
                                        className="HomeMainBtn_blockItemText__xTW7t"
                                        style={{ color: "rgb(243, 244, 245)" }}
                                    >
                                        Смотреть меню
                                    </div>
                                </div>
                                <svg
                                    width={24}
                                    height={24}
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="HomeMainBtn_blockItemArrow__RSNQO"
                                    color="rgb(243, 244, 245)"
                                >
                                    <path
                                        d="M12 2.4C6.7 2.4 2.4 6.698 2.4 12S6.7 21.6 12 21.6c5.302 0 9.6-4.299 9.6-9.6 0-5.302-4.298-9.6-9.6-9.6Zm3.766 10.165-4.8 4.8a.798.798 0 0 1-1.131 0 .8.8 0 0 1 0-1.13L14.069 12 9.835 7.766a.8.8 0 1 1 1.131-1.132l4.8 4.8a.8.8 0 0 1 0 1.132Z"
                                        fill="currentColor"
                                    />
                                </svg>
                            </Link>
                            <a
                                className="HomeMainBtn_blockItem__fRZLa"
                                style={{ backgroundColor: "rgb(32, 32, 34)" }}
                                href="/scanner"
                            >
                                <div className="HomeMainBtn_blockItemLeftTeam__4xvvl">
                                    <svg width="24px" height="24px" viewBox="-0.5 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#F3F4F5"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M1.99023 8.56V16.56C1.99023 17.6209 2.41166 18.6383 3.1618 19.3884C3.91195 20.1386 4.92937 20.56 5.99023 20.56H17.9902C19.0511 20.56 20.0685 20.1386 20.8187 19.3884C21.5688 18.6383 21.9902 17.6209 21.9902 16.56V8.56C21.9902 7.49913 21.5688 6.48171 20.8187 5.73157C20.0685 4.98142 19.0511 4.56 17.9902 4.56H5.99023C4.92937 4.56 3.91195 4.98142 3.1618 5.73157C2.41166 6.48171 1.99023 7.49913 1.99023 8.56Z" stroke="#F3F4F5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M5.99023 8.56V16.56" stroke="#F3F4F5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M8.99023 12.56V16.56" stroke="#F3F4F5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M8.99023 8.56V9.56" stroke="#F3F4F5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M14.9902 8.56V16.56" stroke="#F3F4F5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M12 16.5V16.56" stroke="#F3F4F5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M12 8.56V13.56" stroke="#F3F4F5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M17.9902 13.56V16.56" stroke="#F3F4F5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M17.9902 8.56V10.56" stroke="#F3F4F5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                                    <div
                                        className="HomeMainBtn_blockItemText__xTW7t"
                                        style={{ color: "rgb(243, 244, 245)", marginLeft: "10px" }}
                                    >
                                        Сканировать штрих-код
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div
                            className="HomeContacts_block__w6E3k"
                            style={{ backgroundColor: "rgb(255, 255, 255)" }}
                        >
                            <div className="HomeContactItem_blockInfo__6AHRh">
                                <div className="HomeContactItem_blockInfoIcon__hwgYm">
                                    <svg
                                        width={52}
                                        height={52}
                                        viewBox="0 0 52 52"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <rect width={52} height={52} rx={26} fill="rgb(32, 32, 34)" />
                                        <path
                                            d="M17.2001 16.3999H21.0945C21.6569 16.3999 22.1777 16.6951 22.4665 17.1767L24.4001 20.3999H15.6001V17.9999C15.6001 17.1159 16.3161 16.3999 17.2001 16.3999Z"
                                            fill="rgb(243, 244, 245)"
                                        />
                                        <path
                                            d="M34.8001 18H15.6001V32.4C15.6001 33.284 16.3161 34 17.2001 34H18.8001V33.6C18.8001 32.9376 19.3377 32.4 20.0001 32.4C20.6625 32.4 21.2001 32.9376 21.2001 33.6V34H30.8001V33.6C30.8001 32.9376 31.3377 32.4 32.0001 32.4C32.6625 32.4 33.2001 32.9376 33.2001 33.6V34H34.8001C35.6841 34 36.4001 33.284 36.4001 32.4V19.6C36.4001 18.716 35.6841 18 34.8001 18ZM18.8001 29.2C19.2401 27.4744 20.7009 27.9512 21.0001 26.9776V26.2552C20.8497 26.1752 20.4209 25.6216 20.3761 25.1896C20.2585 25.18 20.0729 25.0624 20.0185 24.5992C19.9889 24.3504 20.1057 24.2104 20.1769 24.1664C20.1769 24.1664 20.0009 23.7656 20.0009 23.3664C20.0001 22.1824 20.5865 21.2 22.0001 21.2C22.7633 21.2 23.0001 21.7416 23.0001 21.7416C23.6825 21.7416 24.0001 22.4896 24.0001 23.3664C24.0001 23.804 23.8241 24.1664 23.8241 24.1664C23.8945 24.2104 24.0113 24.3504 23.9825 24.5992C23.9281 25.0624 23.7425 25.18 23.6249 25.1896C23.5801 25.6216 23.1513 26.1752 23.0009 26.2552V26.9776C23.3001 27.9512 24.7609 27.4744 25.2009 29.2H18.8001ZM32.4001 28.4H27.6001C27.1585 28.4 26.8001 28.0416 26.8001 27.6C26.8001 27.1584 27.1585 26.8 27.6001 26.8H32.4001C32.8417 26.8 33.2001 27.1584 33.2001 27.6C33.2001 28.0416 32.8417 28.4 32.4001 28.4ZM32.4001 25.2H27.6001C27.1585 25.2 26.8001 24.8416 26.8001 24.4C26.8001 23.9584 27.1585 23.6 27.6001 23.6H32.4001C32.8417 23.6 33.2001 23.9584 33.2001 24.4C33.2001 24.8416 32.8417 25.2 32.4001 25.2Z"
                                            fill="rgb(243, 244, 245)"
                                        />
                                    </svg>
                                </div>
                                <div className="HomeContactItem_blockInfoText__6nCf0">
                                    <div
                                        className="HomeContactItem_blockInfoTextUpper__spIS1"
                                        style={{ color: "rgb(32, 32, 34)" }}
                                    >
                                        Контакты
                                    </div>
                                    <div
                                        style={{
                                            width: "fit-content",
                                            borderBottom: "1px solid rgba(33, 33, 33, 0.5)",
                                            cursor: "pointer",
                                            color: "rgba(32, 32, 34, 0.8)"
                                        }}
                                        className="HomeContactItem_blockInfoTextLower__Ue0Gl"
                                    >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="column" />
        </>

    )
}