import { useContext, useState } from "react"
import { createOrder } from "../api/Api"
import { I18nString, MLWord } from "../models/base"
import { Order } from "../models/order"
import { PreviewItem } from "../models/publish"
import { WhatsAppMessage } from "../utils/wa"
import { Checkbox, Form, Input } from "antd"
import { PhoneInput } from "./phoneInput"
import { GlobalContext } from "../ctx/context"

export function CartModal() {
    const { language, setCartIsOpen, cartIsOpen, setCart, cart } = useContext(GlobalContext)

    const [phone, setPhone] = useState<string>("")
    const [name, setName] = useState<string>("")
    const [phoneForBill, setPhoneForBill] = useState<string>("")
    const [addPhoneForBill, setAddPhoneForBill] = useState<boolean>(false)

    if (!cartIsOpen) return null

    function plus(item: PreviewItem) {
        setCart({
            ...cart,
            [item.id]: {
                item: item,
                count: (cart[item.id]?.count || 0) + 1
            }
        })
    }

    function minus(item: PreviewItem) {
        if (cart[item.id]?.count === 1) {
            const newCart = { ...cart }
            delete newCart[item.id]
            setCart(newCart)
            return
        }
        setCart({
            ...cart,
            [item.id]: {
                item: item,
                count: (cart[item.id]?.count || 0) - 1
            }
        })
    }

    function genMessage() {
        let message: I18nString = {
            ru: "Здравствуйте, я хотел бы заказать:\n",
            kz: "Сәлеметсіз бе, мен тапсырыс бергім келеді:\n",
        }

        let total = 0
        for (const key in cart) {
            const item = cart[key]
            message.ru += `${item.item.title[language]} - ${item.count}шт - ${item.item.price * item.count} ₸\n`
            message.kz += `${item.item.title[language]} - ${item.count}шт - ${item.item.price * item.count} ₸\n`
            total += item.item.price * item.count
        }
        message.ru += `Итого: ${total}тг`
        message.kz += `Жалпы: ${total}тг`
        return message[language]
    }

    function onSubmit() {
        let order: Order = {
            id: "",
            createAt: new Date,
            items: [],
            phoneForBill: phoneForBill,
            phone: phone,
            name: name
        }

        for (const key in cart) {
            order.items.push(
                {
                    id: cart[key].item.id,
                    articul: cart[key].item.articul,
                    title: cart[key].item.title,
                    amount: cart[key].count
                })
        }

        createOrder(order)
        let mess = genMessage()
        let link = WhatsAppMessage("77478228057", mess)
        window.open(link, '_blank');
    }
    return (
        <div style={{ opacity: 1 }}>
            <div
                className="Cart_main__UqCr4"
                style={{ backgroundColor: "rgb(255, 255, 255)" }}
            >
                <div className="Cart_container__ptDLS">
                    <div className="CartHeader_header__Bh_DC">
                        <a className="CartHeader_go__back__dzctW" href="#" onClick={() => setCartIsOpen(false)}>
                            <svg
                                width={30}
                                height={30}
                                viewBox="0 0 30 30"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M15 3C21.627 3 27 8.373 27 15C27 21.627 21.627 27 15 27C8.373 27 3 21.627 3 15C3 8.373 8.373 3 15 3ZM10.293 15.707L16.293 21.707C16.488 21.902 16.744 22 17 22C17.256 22 17.512 21.902 17.707 21.707C18.098 21.316 18.098 20.684 17.707 20.293L12.414 15L17.707 9.707C18.098 9.316 18.098 8.684 17.707 8.293C17.316 7.902 16.684 7.902 16.293 8.293L10.293 14.293C9.902 14.684 9.902 15.316 10.293 15.707Z"
                                    fill="rgb(32, 32, 34)"
                                />
                            </svg>
                        </a>
                        <div
                            className="CartHeader_title__gvzNc"
                            style={{ color: "rgb(32, 32, 34)" }}
                        >
                            {MLWord.your_order[language]}
                        </div>
                    </div>
                    {
                        Object.values(cart).map((item) => {
                            return (
                                <div>
                                    <div
                                        className="ItemCartRow_block__3qX3q"
                                        style={{ borderColor: "rgb(231, 231, 231)" }}
                                    >
                                        {item.item.images &&
                                            <div className="ItemCartRow_preview__ObkSP">
                                                <span
                                                    className=" lazy-load-image-background blur lazy-load-image-loaded"
                                                    style={{
                                                        color: "transparent",
                                                        display: "inline-block",
                                                        height: 75,
                                                        width: 75
                                                    }}
                                                >
                                                    <img
                                                        className="ItemCartRow_previewImg__J2cWJ"
                                                        src={`media/${item.item.images}`}
                                                        width={75}
                                                        height={75}
                                                        alt="preview"
                                                        style={{
                                                            filter: "none",
                                                            transition: "filter 0.3s ease-out 0s"
                                                        }}
                                                    />
                                                </span>
                                            </div>
                                        }
                                        <div className="ItemCartRow_info__o_a3p">
                                            <div
                                                className="ItemCartRow_name__ifu06"
                                                style={{ color: "rgb(32, 32, 34)" }}
                                            >
                                                {item.item.title[language]}
                                            </div>
                                            <div className="ItemCartRow_blockFooter__N4JK8">
                                                <div className="ItemCartRow_counter__WkIoW">
                                                    <div
                                                        className="ItemCartRow_counterCircle___MvSe"
                                                        style={{ marginRight: 6 }} onClick={() => minus(item.item)}
                                                    >
                                                        <svg
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            id="checkmark"
                                                        >
                                                            <rect
                                                                width={24}
                                                                height={24}
                                                                rx={12}
                                                                fill="rgb(243, 244, 245)"
                                                            />
                                                            <rect
                                                                x={5}
                                                                y={10}
                                                                width={14}
                                                                height={4}
                                                                fill="rgb(32, 32, 34)"
                                                            />
                                                            <path
                                                                d="M12 2.3999C6.69842 2.3999 2.40002 6.6983 2.40002 11.9999C2.40002 17.3015 6.69842 21.5999 12 21.5999C17.3016 21.5999 21.6 17.3015 21.6 11.9999C21.6 6.6983 17.3016 2.3999 12 2.3999ZM16.8 12.7999H7.20002C6.75842 12.7999 6.40002 12.4423 6.40002 11.9999C6.40002 11.5575 6.75842 11.1999 7.20002 11.1999H16.8C17.2416 11.1999 17.6 11.5575 17.6 11.9999C17.6 12.4423 17.2416 12.7999 16.8 12.7999Z"
                                                                fill="rgb(243, 244, 245)"
                                                            />
                                                        </svg>
                                                    </div>
                                                    <div
                                                        className="ItemCartRow_count__Sms7_"
                                                        style={{ color: "rgb(32, 32, 34)" }}
                                                    >
                                                        {item.count}
                                                    </div>
                                                    <div
                                                        className="ItemCartRow_counterCircle___MvSe"
                                                        style={{ marginLeft: 6 }} onClick={() => plus(item.item)}
                                                    >
                                                        <svg
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <rect
                                                                width={24}
                                                                height={24}
                                                                rx={12}
                                                                fill="rgb(243, 244, 245)"
                                                            />
                                                            <rect
                                                                x={6}
                                                                y={6}
                                                                width={13}
                                                                height={12}
                                                                fill="rgb(32, 32, 34)"
                                                            />
                                                            <path
                                                                d="M12 2.3999C6.69842 2.3999 2.40002 6.6983 2.40002 11.9999C2.40002 17.3015 6.69842 21.5999 12 21.5999C17.3016 21.5999 21.6 17.3015 21.6 11.9999C21.6 6.6983 17.3016 2.3999 12 2.3999ZM16.8 12.7999H12.8V16.7999C12.8 17.2423 12.4416 17.5999 12 17.5999C11.5584 17.5999 11.2 17.2423 11.2 16.7999V12.7999H7.20002C6.75842 12.7999 6.40002 12.4423 6.40002 11.9999C6.40002 11.5575 6.75842 11.1999 7.20002 11.1999H11.2V7.1999C11.2 6.7575 11.5584 6.3999 12 6.3999C12.4416 6.3999 12.8 6.7575 12.8 7.1999V11.1999H16.8C17.2416 11.1999 17.6 11.5575 17.6 11.9999C17.6 12.4423 17.2416 12.7999 16.8 12.7999Z"
                                                                fill="rgb(243, 244, 245)"
                                                            />
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div
                                                    className="ItemCartRow_price__vI2W8"
                                                    style={{ color: "rgb(32, 32, 34)" }}
                                                >
                                                    {item.count * item.item.price} ₸
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className="CartCharges_costs__2vNxw">
                        <div
                            className="CartCharges_costsRow__vys6z"
                            style={{ borderColor: "rgb(231, 231, 231)" }}
                        >
                            <div
                                className="CartCharges_costsRowLabel__NBVxn"
                                style={{ color: "rgb(32, 32, 34)" }}
                            >
                                {MLWord.total[language]}
                            </div>
                            <div
                                className="CartCharges_costsRowPrice__riNaf"
                                style={{ color: "rgb(32, 32, 34)" }}
                            >
                                {Object.values(cart).reduce((acc, item) => acc + (item.item.price * item.count), 0)} ₸
                            </div>
                        </div>
                    </div>
                </div>
                <a
                    className="CartBtn_button__2ru2G CartBtn_button__enter__done___WadL"
                    href="#"
                >
                    {Object.keys(cart).length > 0 &&
                        <div className="CartBtn_button__inner__MiS87">
                            <Form
                                name="wrap"
                                labelCol={{ flex: '110px' }}
                                labelAlign="left"
                                labelWrap
                                wrapperCol={{ flex: 1 }}
                                colon={false}
                                style={{ width: "100%" }}
                            >

                                <Form.Item label={MLWord.name[language]}>
                                    <Input type="text" value={name} onChange={(e) => {
                                        setName(e.currentTarget.value)
                                    }} />
                                </Form.Item>
                                <Form.Item label={MLWord.phone_number[language]}>
                                    <PhoneInput onChange={setPhone} />
                                </Form.Item>

                                <Form.Item label={"         "}>
                                    <Checkbox onChange={(e) => setAddPhoneForBill(e.target.checked)} >{MLWord.add_phone_for_bill[language]}</Checkbox>
                                </Form.Item>

                                <Form.Item label={"          "} style={!addPhoneForBill ? { display: "none" } : {}}>
                                    <PhoneInput onChange={setPhoneForBill} />
                                </Form.Item>
                                <Form.Item>
                                    <div className="ProductBtn_button__inner__cXv35">
                                        <button
                                            className="ProductBtn_btn__o2Ep6"
                                            style={{
                                                backgroundColor: "rgb(32, 32, 34)",
                                                color: "rgb(243, 244, 245)"
                                            }}
                                            onClick={onSubmit}
                                        >
                                            {MLWord.order[language]}
                                        </button>
                                    </div>
                                </Form.Item>

                            </Form>
                        </div>
                    }
                </a>
            </div>
        </div>
    )
}
