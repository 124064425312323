import { Form, Input } from "antd"
import { leaveRequestToItem } from "../api/Api"
import { MLWord } from "../models/base"
import { PhoneInput } from "./phoneInput"
import { useContext, useState } from "react"
import { ItemModalImage } from "./itemImage"
import { GlobalContext } from "../ctx/context"


export function ItemModal() {
    const { itemModal, setItemModal, cart, setCart } = useContext(GlobalContext)

    const { language } = useContext(GlobalContext)
    
    const [phone, setPhone] = useState<string>()
    const [name, setName] = useState<string>()

    if (!itemModal) return null


    function createRequestForItem() {
        leaveRequestToItem({
            articul: itemModal?.articul || "",
            id: itemModal?.id || "",
            title: itemModal?.title || {},
            count: 0,
            phone: phone || "",
            name: name || "",
        })
    }
    return (
        <div style={{ opacity: 1 }}>
            <div
                className="Product_main__Op_dX"
                style={{ backgroundColor: "rgb(255, 255, 255)" }}
            >
                <div className="ProductImage_preview__HKkoz">
                    <div
                        className="ProductImage_cross__ndK_C"
                        style={{ backgroundColor: "rgb(243, 244, 245)" }}
                    >
                        <svg
                            width={40}
                            height={40}
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            color="rgb(32, 32, 34)"
                            onClick={() => setItemModal(null)}
                        >
                            <path
                                d="M20 4C11.164 4 4 11.164 4 20s7.164 16 16 16 16-7.164 16-16S28.836 4 20 4Zm1.885 16 4.391 4.39a1.332 1.332 0 1 1-1.885 1.885c-.206-.203-4.391-4.39-4.391-4.39l-4.39 4.391a1.332 1.332 0 1 1-1.885-1.885c.203-.206 4.39-4.391 4.39-4.391l-4.391-4.39a1.332 1.332 0 1 1 1.885-1.885c.206.203 4.391 4.39 4.391 4.39l4.39-4.391a1.332 1.332 0 1 1 1.885 1.885c-.203.206-4.39 4.391-4.39 4.391Z"
                                fill="currentColor"
                            />
                        </svg>
                    </div>
                    {itemModal.images &&
                        <ItemModalImage images={itemModal.images} video={itemModal.youtubeLink} />
                    }
                </div>
                <div className="Product_info__03P6K" style={{ marginTop: itemModal.images ? 0 : 70 }}>
                    <div
                        className="ProductName_name__KqmK0"
                        style={{ color: "rgb(32, 32, 34)" }}
                    >
                        {itemModal.title[language]}
                    </div>
                    <div
                        className="ProductDescription_description__9sdc8"
                        style={{ color: "rgba(32, 32, 34, 0.8)" }}
                    >
                        {itemModal.description[language]}
                    </div>
                    <div className="ProductPrice_quantity__RQH5Y">
                        <div
                            className="ProductPrice_price__IX0Q2"
                            style={{ color: "rgb(32, 32, 34)" }}
                        >
                            {itemModal.isOn ?
                                itemModal.price + " ₸" :
                                MLWord.not_available[language]
                            }
                        </div>
                    </div>
                    <div className="ProductBtn_button__9OZjQ ProductBtn_button__enter__done__7XR0U">
                        {!itemModal.isOn ?
                            <Form
                                name="wrap"
                                labelCol={{ flex: '110px' }}
                                labelAlign="left"
                                labelWrap
                                wrapperCol={{ flex: 1 }}
                                colon={false}
                                style={{ width:"100%" }}
                            >
                                
                                <Form.Item label={MLWord.name[language]}>
                                    <Input type="text" onChange={(e) => {
                                        setName(e.currentTarget.value)
                                    }} />
                                </Form.Item>
                                <Form.Item label={MLWord.phone_number[language]}>
                                    <PhoneInput onChange={setPhone} />
                                </Form.Item>
                                <Form.Item>
                                    <div className="ProductBtn_button__inner__cXv35">
                                        <button
                                            className="ProductBtn_btn__o2Ep6"
                                            style={{
                                                backgroundColor: "rgb(32, 32, 34)",
                                                color: "rgb(243, 244, 245)"
                                            }}
                                            onClick={createRequestForItem}
                                        >
                                            {MLWord.submit_your_application[language]}
                                        </button>
                                    </div>
                                </Form.Item>

                            </Form>
                            :
                            <div className="ProductBtn_button__inner__cXv35">
                                <button
                                    className="ProductBtn_btn__o2Ep6"
                                    style={{
                                        backgroundColor: "rgb(32, 32, 34)",
                                        color: "rgb(243, 244, 245)"
                                    }}
                                    onClick={() => {
                                        if (!itemModal) return
                                        setCart({
                                            ...cart,
                                            [itemModal.id]: {
                                                item: itemModal,
                                                count: (cart[itemModal.id]?.count || 0) + 1
                                            }
                                        })
                                        setItemModal(null)
                                    }
                                    }
                                >
                                    {MLWord.add_to_cart[language]}
                                </button>
                            </div>
                        }

                    </div>
                </div>
            </div>
        </div>

    )
}


