import { useContext, useRef } from "react"
import { Section } from "../models/section"
import { GlobalContext } from "../ctx/context"

interface SectionHeaderProps {
    sections: Section[]
    setSectionIndex: React.Dispatch<React.SetStateAction<number>>
    sectionIndex: number
}

export function SectionHeader(props: SectionHeaderProps) {
    const {language} = useContext(GlobalContext)
    return (
        <div className="SectionsRowList_sliderMenus__Xl1PT">
            {props.sections.map((section, index) => {
                return (
                    <div
                        className={props.sectionIndex === index ? "--active SectionItem_MediumSliderBlock__bXupi" : "SectionItem_MediumSliderBlock__bXupi"}
                        onClick={() => {
                            props.setSectionIndex(index)
                        }}
                        key={section.id + "header_section"}
                        onMouseOut={() => {
                            let ref = document.getElementById(`section-name+${section.id}`)
                            if (ref?.style) {
                                ref.style.color = "rgba(32, 32, 34, 0.8)"
                            }
                        }}
                        onMouseOver={() => {
                            let ref = document.getElementById(`section-name+${section.id}`)
                            if (ref?.style) {
                                ref.style.color = "#fff"
                            }
                        }}
                    >
                        <span
                            className=" lazy-load-image-background blur lazy-load-image-loaded"
                            style={{ color: "transparent", display: "inline-block" }}
                        >
                            <img
                                src={"/media/" + section.image}
                                className="SectionItem_preview__tpxeX"
                                style={{ filter: "none", transition: "filter 0.3s ease-out 0s" }}
                                alt="section-preview"
                            />
                        </span>
                        <div className="SectionItem_innerBlock__e_nhZ">
                            <div 
                                className="SectionItem_text__S64Wa"
                                style={{ color: "rgba(32, 32, 34, 0.8);" }}
                                id={`section-name+${section.id}`}
                            >
                                {section.name[language]}
                            </div>
                        </div>
                    </div>

                )
            })}
        </div>

    )
}