import { createContext } from "react";
import { Preview, PreviewItem } from "../models/publish";
import { ItemModal } from "../components/itemModal";
import { ICart } from "../models/cart";
import { MessageInstance } from "antd/es/message/interface";

interface ContextProps {
    language: string
    setLang: React.Dispatch<React.SetStateAction<string>>
    preview: Preview | null
    itemModal: PreviewItem | null
    setItemModal: React.Dispatch<React.SetStateAction<PreviewItem | null>>
    cart: ICart,
    setCart: React.Dispatch<React.SetStateAction<ICart>>
    cartIsOpen: boolean,
    setCartIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    messageApi: MessageInstance
}

export const GlobalContext = createContext<ContextProps>({
    language: "ru",
    setLang: () => { },
    preview: null,
    itemModal: null,
    setItemModal: () => { },
    cart: {},
    setCart: () => { },
    cartIsOpen: false,
    setCartIsOpen: () => { },
    messageApi: {} as MessageInstance
});