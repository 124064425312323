import { Button, Card, Form, Space, Switch, Upload } from "antd";
import FormItem from "antd/es/form/FormItem";
import { uploadImage, saveSection, getCategory, getSection } from "../api/Api";
import { I18nInputs } from "../components/i18nInput";
import { Section } from "../models/section";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export function SaveSectionPage() {
    const { id } = useParams();
    const navigate = useNavigate();

    const [section, setSection] = useState<Section>({
        id: '',
        name: { ru: '', kz: '' },
        image: '',
        withItems: false,
    });

    useEffect(() => {
        if (!id) return
        getSection(id).then(section => {
          if (!section.data) return
          setSection(section.data);
        });
      }, [id]);

    return (
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            <Card title="Create item" size="default">
                <Form name="basic">
                    <I18nInputs label="Title" value={section.name} onChange={name => setSection({ ...section, name })} />
                    <FormItem label={"Image"}>
                        <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            onChange={e => {
                                const file = e.file.originFileObj;
                                if (file) {
                                    uploadImage(file).then(r => {
                                        if (r.filename) {
                                            setSection({ ...section, image: r.filename });
                                        }
                                    }
                                    );
                                }
                            }}
                        >
                            {section.image ? <img src={`/media/${section.image}`} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                        </Upload>
                    </FormItem>
                    <FormItem label={"With items"}>
                        <Switch checked={section.withItems} onChange={withItems => setSection({ ...section, withItems })} />
                    </FormItem>
                    <Space wrap={true}>
                        <Button type="primary" onClick={() => saveSection(section).then(r => !r.err && navigate("/admin"))}>Save</Button>
                        <Button type="default" onClick={() => navigate("/admin")}>Cancel</Button>
                    </Space>
                </Form>
            </Card>
        </Space>
    );
}

const uploadButton = (
    <div>
        <div className="ant-upload-text">Upload</div>
    </div>
);