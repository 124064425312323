import axios from "axios";
import { Category } from "../models/category";
import { Item, ItemRequest } from "../models/item";
import { Preview } from "../models/publish";
import { Section } from "../models/section";
import { Order } from "../models/order";

const baseUrl = 'https://sulu.kz/api';
// const baseUrl = 'http://localhost:8080/api';


interface apiResponse<T> {
    data: T;
    err: string | null;
}


export const getPublish = async (): Promise<apiResponse<Preview>> => {
    try {
        const response = await axios.get<apiResponse<Preview>>(`${baseUrl}/preview`);
        return response.data;
    } catch (error) {
        console.log(error);
        return { data: { sections: null }, err: "Error while fetching publish status" };
    }
}



export const getSections = async (): Promise<apiResponse<Section[]>> => {
    try {
        const response = await axios.get<apiResponse<Section[]>>(`${baseUrl}/admin/sections`);
        return response.data;
    } catch (error: any) {
        console.log(error);
        if (error?.response?.status === 401) {
            let password = window.prompt("Введите пароль")
            if (password) {
                await axios.post<apiResponse<null>>(`${baseUrl}/login`, { password, login: "admin" });
                window.location.reload();
            }
        }
        return { data: [], err: "Error while fetching sections" };
    }
}

export const getSection = async (id: string): Promise<apiResponse<Section>> => {
    try {
        const response = await axios.get<apiResponse<Section>>(`${baseUrl}/admin/sections/${id}`);
        return response.data;
    } catch (error:any) {
        console.log(error);
        if (error?.response?.status === 401) {
            let password = window.prompt("Введите пароль")
            if (password) {
                await axios.post<apiResponse<null>>(`${baseUrl}/login`, { password, login: "admin" });
                window.location.reload();
            }
        }
        return { data: {} as Section, err: "Error while fetching section" };
    }
}

export const saveSection = async (section: Section): Promise<apiResponse<Section>> => {
    try {
        const response = await axios.post<apiResponse<Section>>(`${baseUrl}/admin/section`, section);
        return response.data;
    } catch (error:any) {
        console.log(error);
        if (error?.response?.status === 401) {
            let password = window.prompt("Введите пароль")
            if (password) {
                await axios.post<apiResponse<null>>(`${baseUrl}/login`, { password, login: "admin" });
                window.location.reload();
            }
        }
        return { data: {} as Section, err: "Error while creating section" };
    }
}

export const getCategories = async (): Promise<apiResponse<Category[]>> => {
    try {
        const response = await axios.get<apiResponse<Category[]>>(`${baseUrl}/admin/categories`);
        return response.data;
    } catch (error:any) {
        console.log(error);
        if (error?.response?.status === 401) {
            let password = window.prompt("Введите пароль")
            if (password) {
                await axios.post<apiResponse<null>>(`${baseUrl}/login`, { password, login: "admin" });
                window.location.reload();
            }
        }
        return { data: [], err: "Error while fetching categories" };
    }
}

export async function getCategory(id: string): Promise<apiResponse<Category>> {
    try {
        const response = await axios.get<apiResponse<Category>>(`${baseUrl}/admin/categories/${id}`);
        return response.data;
    } catch (error:any) {
        console.log(error);
        if (error?.response?.status === 401) {
            let password = window.prompt("Введите пароль")
            if (password) {
                await axios.post<apiResponse<null>>(`${baseUrl}/login`, { password, login: "admin" });
                window.location.reload();
            }
        }
        return { data: {} as Category, err: "Error while fetching category" };
    }
}

export const saveCategory = async (category: Category): Promise<apiResponse<Category>> => {
    try {
        const response = await axios.post<apiResponse<Category>>(`${baseUrl}/admin/category`, category);
        return response.data;
    } catch (error:any) {
        console.log(error);
        if (error?.response?.status === 401) {
            let password = window.prompt("Введите пароль")
            if (password) {
                await axios.post<apiResponse<null>>(`${baseUrl}/login`, { password, login: "admin" });
                window.location.reload();
            }
        }
        return { data: {} as Category, err: "Error while creating category" };
    }
}

export const publish = async (): Promise<apiResponse<Category>> => {
    try {
        const response = await axios.post<apiResponse<Category>>(`${baseUrl}/admin/publish`);
        return response.data;
    } catch (error:any) {
        console.log(error);
        if (error?.response?.status === 401) {
            let password = window.prompt("Введите пароль")
            if (password) {
                await axios.post<apiResponse<null>>(`${baseUrl}/login`, { password, login: "admin" });
                window.location.reload();
            }
        }
        return { data: {} as Category, err: "Error while publishing" };
    }
}


export const getItems = async (): Promise<apiResponse<Item[]>> => {
    try {
        const response = await axios.get<apiResponse<Item[]>>(`${baseUrl}/admin/items`);
        return response.data;
    } catch (error:any) {
        console.log(error);
        if (error?.response?.status === 401) {
            let password = window.prompt("Введите пароль")
            if (password) {
                await axios.post<apiResponse<null>>(`${baseUrl}/login`, { password, login: "admin" });
                window.location.reload();
            }
        }
        return { data: [], err: "Error while fetching items" };
    }
}

export const getItem = async (id: string): Promise<apiResponse<Item>> => {
    try {
        const response = await axios.get<apiResponse<Item>>(`${baseUrl}/admin/items/${id}`);
        return response.data;
    } catch (error:any) {
        console.log(error);
        if (error?.response?.status === 401) {
            let password = window.prompt("Введите пароль")
            if (password) {
                await axios.post<apiResponse<null>>(`${baseUrl}/login`, { password, login: "admin" });
                window.location.reload();
            }
        }
        return { data: {} as Item, err: "Error while fetching item" };
    }
}

export const saveItem = async (item: Item): Promise<apiResponse<Item>> => {
    try {
        const response = await axios.post<apiResponse<Item>>(`${baseUrl}/admin/item`, item);
        return response.data;
    } catch (error:any) {
        console.log(error);
        if (error?.response?.status === 401) {
            let password = window.prompt("Введите пароль")
            if (password) {
                await axios.post<apiResponse<null>>(`${baseUrl}/login`, { password, login: "admin" });
                window.location.reload();
            }
        }
        return { data: {} as Item, err: "Error while creating item" };
    }
}

export const deleteCategory = async (id: string): Promise<apiResponse<null>> => {
    try {
        const response = await axios.delete<apiResponse<null>>(`${baseUrl}/admin/categories/${id}`);
        return response.data;
    } catch (error:any) {
        console.log(error);
        if (error?.response?.status === 401) {
            let password = window.prompt("Введите пароль")
            if (password) {
                await axios.post<apiResponse<null>>(`${baseUrl}/login`, { password, login: "admin" });
                window.location.reload();
            }
        }
        return { data: null, err: "Error while deleting category" };
    }
}

export const deleteItem = async (id: string): Promise<apiResponse<null>> => {
    try {
        const response = await axios.delete<apiResponse<null>>(`${baseUrl}/admin/items/${id}`);
        return response.data;
    } catch (error:any) {
        console.log(error);
        if (error?.response?.status === 401) {
            let password = window.prompt("Введите пароль")
            if (password) {
                await axios.post<apiResponse<null>>(`${baseUrl}/login`, { password, login: "admin" });
                window.location.reload();
            }
        }
        return { data: null, err: "Error while deleting item" };
    }
}

// upload image
export const uploadImage = async (file: File): Promise<{ filename: string }> => {
    try {
        const formData = new FormData();
        formData.append("file", file);
        const response = await axios.post<{ filename: string }>(`${baseUrl}/media`, formData);
        return response.data;
    } catch (error:any) {
        console.log(error);
        if (error?.response?.status === 401) {
            let password = window.prompt("Введите пароль")
            if (password) {
                await axios.post<apiResponse<null>>(`${baseUrl}/login`, { password, login: "admin" });
                window.location.reload();
            }
        }
        return { filename: "error" };
    }
}

export const UploadExcel = async (file: File): Promise<apiResponse<null>> => {
    try {
        const formData = new FormData();
        formData.append("file", file);
        const response = await axios.post<apiResponse<null>>(`${baseUrl}/admin/excel`, formData);
        return response.data;
    } catch (error:any) {
        console.log(error);
        if (error?.response?.status === 401) {
            let password = window.prompt("Введите пароль")
            if (password) {
                await axios.post<apiResponse<null>>(`${baseUrl}/login`, { password, login: "admin" });
                window.location.reload();
            }
        }
        return { data: null, err: "Error while uploading excel" };
    }
}

export const downloadExcel = async (): Promise<null> => {
    try {
        const res = await fetch(`${baseUrl}/admin/excel`, {
            method: 'GET',
        });
        // dowload excel file\
        let blob = await res.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        // get filename from response header
        const filename = res.headers.get('content-disposition')?.split(';')[1].split('=')[1].replace(/\"/g, '');
        link.setAttribute('download', filename || 'download.xlsx');
        document.body.appendChild(link);
        link.click();
        return null;
    } catch (error:any) {
        console.log(error);
        if (error?.response?.status === 401) {
            let password = window.prompt("Введите пароль")
            if (password) {
                await axios.post<apiResponse<null>>(`${baseUrl}/login`, { password, login: "admin" });
                window.location.reload();
            }
        }
        return null;
    }
}

export const createOrder = async (order: Order): Promise<apiResponse<null>> => {
    try {
        const response = await axios.post<apiResponse<null>>(`${baseUrl}/order`, order);
        return response.data;
    } catch (error:any) {
        console.log(error);
        if (error?.response?.status === 401) {
            let password = window.prompt("Введите пароль")
            if (password) {
                await axios.post<apiResponse<null>>(`${baseUrl}/login`, { password, login: "admin" });
                window.location.reload();
            }
        }
        return { data: null, err: "Error while creating order" };
    }
}

export const getOrders = async (): Promise<apiResponse<Order[]>> => {
    try {
        const response = await axios.get<apiResponse<Order[]>>(`${baseUrl}/admin/orders`);
        return response.data
    } catch (error:any) {
        console.log(error);
        if (error?.response?.status === 401) {
            let password = window.prompt("Введите пароль")
            if (password) {
                await axios.post<apiResponse<null>>(`${baseUrl}/login`, { password, login: "admin" });
                window.location.reload();
            }
        }
        return { data: [], err: "Error while fetching orders" };
    }
}

export const leaveRequestToItem = async (item: ItemRequest): Promise<apiResponse<null>> => {
    try {
        const response = await axios.post<apiResponse<null>>(`${baseUrl}/leave-request`, item);
        return response.data;
    } catch (error:any) {
        console.log(error);
        if (error?.response?.status === 401) {
            let password = window.prompt("Введите пароль")
            if (password) {
                await axios.post<apiResponse<null>>(`${baseUrl}/login`, { password, login: "admin" });
                window.location.reload();
            }
        }
        return { data: null, err: "Error while leave request" };
    }
}

export const getLeaveRequests = async (): Promise<apiResponse<ItemRequest[]>> => {
    try {
        const response = await axios.get<apiResponse<ItemRequest[]>>(`${baseUrl}/admin/leave-requests`)
        return response.data;
    } catch (error:any) {
        console.log(error);
        if (error?.response?.status === 401) {
            let password = window.prompt("Введите пароль")
            if (password) {
                await axios.post<apiResponse<null>>(`${baseUrl}/login`, { password, login: "admin" });
                window.location.reload();
            }
        }
        return { data: [], err: "Error while fetching leave requests" };
    }
}


