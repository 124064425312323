import { Table } from "antd";
import { useEffect, useState } from "react";
import { getLeaveRequests } from "../api/Api";
import { timeSince } from "../utils/time";

interface itemrequest {
    articul: string;
    id: string;
    title: string;
    count: number,
    phone: string;
    name: string;
}

export function ItemRequests() {

    const [requests, setRequests] = useState<itemrequest[]>([])

    useEffect(() => {
        getLeaveRequests().then((ordersApi) => {
            setRequests((ordersApi.data || []).map((order) => {
                console.log(order)
                return {
                    articul: order.articul,
                    id: order.id,
                    title: order.title.ru || "",
                    count: order.count,
                    phone: order.phone,
                    name: order.name,
                }
            }
            ))

        })
    }, [])

    const columns = [
        {
            title: 'Articul',
            dataIndex: 'articul',
            key: 'articul',
        },
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Count',
            dataIndex: 'count',
            key: 'count',
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
    ];


    return (
        <div>
            Заявки на товары
            <Table dataSource={requests} columns={columns} />
        </div>
    )
}