import { useNavigate } from "react-router-dom";
import { MLWord } from "../models/base";

export function SearchBar() {
    const navigate = useNavigate()
    return (
        <div className="ListSearchBar_searchBar__i2kfM">
            <label
                className="ListSearchBar_search__zUyeg"
                style={{ backgroundColor: "rgb(243, 244, 245)" }}
            >
                <div className="ListSearchBar_searchIcon__WrQNl">
                    <svg
                        width={14}
                        height={14}
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M6.5 1a5.5 5.5 0 0 1 4.383 8.823l3.896 3.9a.75.75 0 0 1-1.061 1.06l-3.895-3.9A5.5 5.5 0 1 1 6.5 1Zm0 1.5a4 4 0 1 0 0 8 4 4 0 0 0 0-8Z"
                            fill="#9398A1"
                        />
                    </svg>
                </div>
                <input
                //TODO: add language
                    placeholder={MLWord.search["ru"]}
                    className="ListSearchBar_searchInput__VoqMk"
                    type="text"
                    style={{ color: "rgba(32, 32, 34, 0.8)" }}
                    onClick={() => {
                        navigate("/search")
                    }}
                />
            </label>
        </div>
    )
}