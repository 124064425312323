import FormItem from "antd/es/form/FormItem";
import { I18nString } from "../models/base";
import { Input } from "antd";

interface I18nInputsProps {
  label: string;
  value: I18nString;
  onChange: (value: I18nString) => void;
}

export function I18nInputs(props: I18nInputsProps) {
  return (
    <FormItem isList={true} label={props.label}>
        <FormItem label={"RU"}>
          <Input id={props.label + "RU"} type="text" value={props.value.ru} onChange={e => props.onChange({ ...props.value, ru: e.target.value })} />
        </FormItem>
        <FormItem label="KZ"> 
          <Input id={props.label + "KZ"} type="text" value={props.value.kz} onChange={e => props.onChange({ ...props.value, kz: e.target.value })} />
        </FormItem>
    </FormItem>
  );
}

export function I18nTextArea(props: I18nInputsProps) {
  return (
    <FormItem isList={true} label={props.label}>
        <FormItem label={"RU"}>
          <Input.TextArea id={props.label + "RU"} value={props.value.ru} onChange={e => props.onChange({ ...props.value, ru: e.target.value })} />
        </FormItem>
        <FormItem label="KZ"> 
          <Input.TextArea id={props.label + "KZ"} value={props.value.kz} onChange={e => props.onChange({ ...props.value, kz: e.target.value })} />
        </FormItem>
    </FormItem>
  );
}